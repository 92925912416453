@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

:root {
	--green-color: #2d923b;
	--yellow-app: #ffc107;
	--yellow-color: #fdec09;
	--red-color: #cb333b;
	--grey-color: rgb(0 0 0 / 54%);
	--disabled-color: rgba(0, 0, 0, 0.38);
	--primary-color: #007bff;
	--background-color: #f2f2f2;

	--red-sanii: #c41936;
	--white-sanii: #ffffff;
}

html,
body {
	font-family: 'Raleway', sans-serif;
	background-color: #f2f2f2;
	height: 100%;
}

app-root {
	display: flex;
	flex-direction: column;
	height: 100vh;
}

app-header-container {
	flex: 0 0 auto;
}

app-container,
app-home,
app-vendedores,
app-qr,
app-creditos,
app-simulador,
app-login,
app-signing-process-container,
app-error-page,
app-create-or-edit-form {
	display: flex;
	flex: 1 1 auto;
}

input,
select,
.credilow-plan-cuota,
ng-multiselect-dropdown,
textarea,
.credilow-documento {
	box-shadow: 0px 3px 6px #00000029;
}

input[type='text'],
input[type='password'],
input[type='number'],
input[type='date'],
input[type='email'],
ng-multiselect-dropdown,
select,
.credilow-plan-cuota,
textarea,
.credilow-documento {
	width: 100%;
	padding: 12px 20px;
	margin-bottom: 16px;
	border: 1px solid #fff;
	box-sizing: border-box;
	display: inline-block;
	border-radius: 10px;
	font-size: 14px;
	background-color: #ffffff;
}

input[type='submit'] {
	font-size: 20px;
	color: var(--red-color);
	font-weight: bold;
	width: 100%;
	background-color: #ffffff;
	border: none;
	border-radius: 50px;
	cursor: pointer;
	padding-top: 8px;
	padding-bottom: 6px;
	text-transform: uppercase;
}

input[type='submit']:hover:enabled {
	background-color: #eee;
}

input:focus,
select:focus {
	outline: none;
}

input:disabled {
	background-color: #e3e3e3;
	color: #343a405c;
	cursor: default;
	border: 1px solid #e3e3e3;
}

select:invalid {
	color: #a2000a;
}

h6 {
	font-size: 17px;
	font-weight: bold;
	margin-bottom: 16px;
}

::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #c7c7c7;
	opacity: 1; /* Firefox */
}

.container {
	padding-top: 30px;
	padding-bottom: 15px;
}

.credilow-shadow {
	box-shadow: 0px 3px 6px #00000029;
}

.credilow-datos-personales-background {
	background-color: #f2f2f2;
	/*  top: 0;
  bottom: 0;*/
	width: 100%;
	z-index: -1;
	height: 100%;
}

.credilow-text {
	text-align: center;
	font-size: 17px;
}

.credilow-dni-tabs {
	margin-top: 6px;
}

.credilow-dni-tabs-header {
	display: flex;
	flex-wrap: wrap;
	padding-right: 17px;
	padding-left: 17px;
}

.credilow-dni-tab {
	width: 100%;
	text-align: center;
	font-size: 16px;
	color: var(--red-color);
	border-bottom: 3px solid var(--red-color);
	font-weight: bold;
}

.credilow-dni-tabs-body {
	height: 100%;
	/* background-color: #D1000D08; */
	/* margin-top: 13px; */
	/* border: 1px dashed #D1000D3D; */
	border-radius: 10px;
	/* padding: 10px; */
	text-align: center;
}

.credilow-dni-tabs-body i {
	color: #ebc6c8;
	font-size: 27px;
	display: block;
}

.credilow-boton-rosa {
	display: block;
	margin-top: 5px;
	color: var(--red-color);
	font-size: 10px;
	background-color: #d1000d26;
	border: none;
	padding: 12px 38px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	border-radius: 17px;
	font-weight: bold;
	text-transform: uppercase;
}

.credilow-navigate {
	display: flex;
	flex-wrap: wrap;
	/* padding-right: 23px; */
	/* padding-left: 23px; */
	margin-top: 44px;
	text-align: center;
}

.credilow-navigate p {
	font-size: 10px;
}

.credilow-previous-button {
	font-size: 16px;
	color: #ffffff;
	font-weight: bold;
	width: 100%;
	background-color: var(--red-color);
	border: none;
	border-radius: 50px;
	cursor: pointer;
	padding-top: 10px;
	padding-bottom: 10px;
	text-transform: uppercase;
}

.credilow-previous-button.sanii {
	color: var(--red-sanii);
	background-color: var(--white-sanii);
}

.credilow-previous-button:hover:enabled {
	background-color: #a2000a;
	color: #ffffff;
}

.credilow-previous-button:disabled {
	background-color: #e3e3e3;
	color: #c4c4c4;
	cursor: default;
}

.credilow-50 {
	width: 50%;
	padding-right: 6px;
	padding-left: 6px;
	text-align: center;
}

.credilow-25 {
	width: 25%;
	padding-right: 4px;
	padding-left: 4px;
	text-align: center;
}

.credilow-success {
	color: #075f03 !important;
}

.credilow-next-button {
	font-size: 16px;
	color: var(--red-color);
	font-weight: bold;
	width: 100%;
	background-color: var(--yellow-color);
	border: none;
	border-radius: 50px;
	cursor: pointer;
	padding-top: 10px;
	padding-bottom: 10px;
	text-transform: uppercase;
}
.credilow-next-button.sanii {
	background-color: var(--red-sanii);
	color: var(--white-sanii);
}

.credilow-next-button:hover:enabled {
	background-color: #eee511;
	color: var(--red-color);
}

.credilow-next-button:disabled {
	background-color: #e3e3e3;
	color: #c4c4c4;
	cursor: default;
}

.selectdiv {
	position: relative;
}

select::-ms-expand {
	display: none;
}

.selectdiv:after {
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	content: '\f078';
	right: 15px;
	/*Adjust for position however you want*/

	padding: 16px 0;
	font-size: 12px;

	position: absolute;
	pointer-events: none;
}

.selectdiv select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.credilow-yes-no {
	display: flex;
	flex-wrap: wrap;
	position: relative;
	margin-left: 20px;
	margin-right: 20px;
	margin-bottom: 10px;
}

.credilow-yes-no-option {
	background-color: #e3e3e3;
	width: 50%;
	text-align: center;
	font-size: 14px;
	color: #343a405c;
	font-weight: bold;
	cursor: pointer;
	text-transform: uppercase;
	padding-top: 12px;
	padding-bottom: 9px;
}

.credilow-yes-no-option-left {
	-moz-border-radius-topleft: 50px;
	border-top-left-radius: 50px;
	-moz-border-radius-bottomleft: 50px;
	border-bottom-left-radius: 50px;
}

.credilow-yes-no-option-right {
	-moz-border-radius-topright: 50px;
	border-top-right-radius: 50px;
	-moz-border-radius-bottomright: 50px;
	border-bottom-right-radius: 50px;
}

.credilow-yes-no-option-active {
	background-color: #ffffff;
	color: var(--red-color);
	box-shadow: -1px 0px 6px #00000029;
}

.credilow-yes-no-title {
	margin-bottom: 8px;
	margin-left: 48px;
	margin-right: 48px;
	text-align: center;
	font-size: 14px;
}

.credilow-fuentes-verificadas-banner {
	background-color: #dfdfdf;
	padding-top: 14px;
	padding-bottom: 12px;
	position: relative;
	text-align: center;
}

.credilow-fuentes-verificadas-banner p {
	font-size: 10px;
	margin-bottom: 0px;
}

.credilow-mb-2 {
	margin-bottom: 2px !important;
}

.credilow-validar-celular {
	padding: 30px 38px 0px 38px;
	text-align: center;
}

.credilow-validar-celular input {
	text-align: center;
}

.credilow-codigo {
	display: flex;
	flex-wrap: wrap;
	position: relative;
	margin: 20px 78px 10px;
}

.credilow-codigo input {
	padding-left: 10px;
	padding-right: 10px;
	text-align: center;
}

.credilow-link {
	text-align: center;
	width: 100%;
}

.credilow-link a {
	font-size: 10px;
	color: var(--red-color);
}

.credilow-referencias {
	/* padding: 30px 38px 0px 38px; */
}

.credilow-referencias .credilow-yes-no {
	margin-left: 45px;
	margin-right: 45px;
}

.credilow-referencias h6 {
	font-size: 17px;
	font-weight: bold;
	margin-bottom: 10px;
}

.credilow-referencias hr {
	margin-top: 23px;
	margin-bottom: 17px;
}

.credilow-referencias input[type='text'],
input[type='password'],
select {
	margin-bottom: 24px;
}

.credilow-monto-solicitado {
	/* margin-top: 70px;
  margin-left: 43px;
  margin-right: 43px; */
	text-align: center;
}

.credilow-monto-solicitado h6 {
	font-size: 17px;
	font-weight: bold;
	margin-bottom: 16px;
}

.credilow-footer {
	/*
  position: fixed;
  bottom: 0;
  width: 100%;
  */
}

.credilow-planes {
	/* margin: 40px 25px 0px 25px; */
}

.credilow-plan-cuota {
	margin-bottom: 12px;
	border: 0px;
	padding-top: 14px;
	padding-bottom: 14px;
	cursor: pointer;
}

.credilow-plan-cuota-active {
	background: linear-gradient(90deg, #2d923b 8px, #ffffff 8px);
}

.credilow-plan-cuota-dot {
	border-radius: 50%;
	background-color: #ffffff;
	display: inline-block;
	height: 15px;
	width: 15px;
	border: 1px solid #707070;
	margin-right: 15px;
	margin-left: 2px;
	vertical-align: middle;
}

.credilow-plan-cuota-dot-active {
	border-radius: 50%;
	display: inline-block;
	height: 19px;
	width: 19px;
	margin-right: 15px;
	vertical-align: middle;
	background: radial-gradient(#2d923b 50%, rgba(255, 0, 0, 0) 51%);
	border: 2px solid #2d923b;
}

.credilow-plan-cuota-name {
	font-size: 10px;
	color: #3daf28;
	text-transform: uppercase;
	margin-left: 7px;
}

.credilow-plan-cuota-primer-vencimiento {
	color: #000000;
}

.credilow-plan-cuota-amount {
	color: #343a4080;
	float: right;
}

.credilow-plan-cuota-amount-active {
	color: #343a40;
}

.credilow-container {
	/* position: fixed; */
	overflow-x: hidden;
	transition: left 0.5s ease; /* Animation styles are just for demo */
	right: 0;
	width: 100%;
	top: 0;
	bottom: 0;
}

.push {
	right: 250px !important;
}

.credilow-menu {
	position: fixed;
	right: 0;
	width: 250px;
	height: 100%;
	background-color: var(--yellow-color);
	color: #7f6e19;
	font-size: 14px;
	font-weight: bold;
	padding: 20px;
	cursor: pointer;
	z-index: 10;
	transform: translateX(100%);
	transition: transform 0.3s ease-out;
	margin-top: 37px;
}

.credilow-menu.show {
	transform: translateX(0);
}

.credilow-menu.sanii {
	background-color: var(--white-sanii);
	color: var(--red-sanii);
}

.is-invalid {
	border-color: #ea9ba0 !important;
}

input[type='number'] {
	-moz-appearance: textfield;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.toast-container {
	margin-top: 60px;
}

.credilow-planes-filtros {
	margin: 20px;
}

.dropdown-btn {
	border: #ffffff 0px solid !important;
}

.credilow-adjuntos {
	margin: 10px 25px 0px 25px;
}

.credilow-documento {
	margin-bottom: 12px;
	border: 0px;
	padding-top: 14px;
	padding-bottom: 14px;
}

.credilow-borrar-adjunto {
	float: right;
	color: var(--red-color);
	cursor: pointer;
}

.credilow-adjuntar {
	float: right;
	cursor: pointer;
}

.credilow-adjuntar-bloqueado {
	float: right;
	color: #c4c4c4;
}

.fill {
	min-height: 100%;
	height: 100%;
}

.fill-10 {
	/* min-height: 10%; */
	height: 10%;
}

.fill-90 {
	/* min-height: 100%; */
	height: 90%;
}

.fill-95 {
	/* min-height: 100%; */
	height: 95%;
}
.fill-85 {
	/* min-height: 100%; */
	height: 85%;
}

.space-between-buttons {
	justify-content: space-around;
	display: flex;
	/* position: absolute;
  bottom: 0; */
	width: 100%;
	margin-top: auto;
}

.credilow-header {
	position: relative;
	z-index: 0;
	background-color: var(--yellow-color);
	/* width: 100%; */
	text-align: center;
	font-size: 14px;
	font-weight: bold;
	padding: 15px 15px 0px;
	color: #7f6e19;
}
.credilow-header.sanii {
	background-color: var(--white-sanii);
	color: var(--red-sanii);
}

.headerIcon {
	font-size: 40px;
}

html,
body {
	height: 100%;
}
body {
	margin: 0;
}

webcam video {
	margin-bottom: -7px;
	overflow: hidden;
	width: 100%;
	height: 100%;
	object-fit: cover;
	max-height: 300px;
}

.small-btn {
	font-size: 12px;
	color: #ffffff;
	border-radius: 50px !important;
	cursor: pointer;
	margin-bottom: 20px !important;
	height: 50%;
	word-wrap: break-word;
	max-width: 100%;
	text-align: center;
	border: none;
}

.small-btn:disabled {
	background-color: #e3e3e3 !important;
	color: #c4c4c4;
	cursor: default;
}

.modify-btn {
	background-color: var(--grey-color);
}

.send-btn {
	background-color: var(--red-color) !important;
}

.second-btn {
	background-color: var(--yellow-color) !important;
}

.option-btn {
	font-size: 12px;
	color: #ffffff;
	border-radius: 50px !important;
	cursor: pointer;
	height: 50%;
	word-wrap: break-word;
	max-width: 100%;
	text-align: center;
}

.material-icons {
	display: flex;
	justify-content: center;
	align-items: center;
}

.border {
	border-radius: 10px;
	padding: 5px;
    border: 1px solid var(--credilow-black-20, #D0D0D0);
}

.mat-select-search-inner-row{
	height: 0px;
}

.search-box {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 1; 
	background-color: white; 
	border-top: 1px solid #ccc; 
  }

  